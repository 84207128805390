<template>
  <sub-page
      v-model="$store.state.settings.pageData"
      :title="$t('Disciplines')"
      :subtitle="$t('Structure')"
      icon="mdi-school-outline"
      :header-action-text="$t('wsu.structure.discipline.add')"
      :header-dropdown-actions="headerDropdownActionsSelect"
      :no-content-condition="items.length === 0"
      :no-content-text="$t('wsu.structure.discipline.no_content')"
      :no-content-button-action="openNewDiscipline"
      :no-content-button-text="$t('wsu.structure.discipline.add')"
      fill-height
  >

    <template #default >

      <ws-data-table
          :items="items"
          :headers="headers"
          :context-actions-select="contextActionsSelect"
          @itemSelect="selectedItem = $event"
          class="mt-5"
      >
        <template #item.name="{item}">
          <h5 :style="`color : ${wsACCENT}`">{{ item.name }}</h5>
          <h5 class="font-weight-regular">{{ item.department_name }}</h5>
        </template>

        <template #item.on_choice="{item}">
          <div class="d-flex justify-center">
            <v-icon v-if="item.on_choice" :color="wsACCENT">
              mdi-check-circle-outline
            </v-icon>
          </div>
        </template>

        <template #item.is_general="{item}">
          <div class="d-flex justify-center">
            <v-icon v-if="item.is_general" :color="wsACCENT">
              mdi-check-circle-outline
            </v-icon>
          </div>
        </template>

        <template #item.degrees_names="{item , index}">
          <div>
            <template  v-if="item.degrees_names">
              <h5 class="text-no-wrap" v-for="(degree,i) in item.degrees_names" :key="index + ',' + i ">
                {{ degree }}
              </h5>
            </template>
          </div>
        </template>


      </ws-data-table>
    </template>

    <template #dialog>
      <!--  AddEdit Discipline -->
      <ws-dialog
          v-model="displayDialog"
          :title="$t(!editEntity ? 'wsu.structure.discipline.add' : 'wsu.structure.discipline.edit')"
          @save="addEntity"
          @delete="deleteEntity"
          :confirm-delete-code="selectedItem ? selectedItem.uuid : null"
          :show-delete="editEntity"
      >

        <ws-text-field
            class="mt-6"
            v-model="entityData.name"
            :label="$t('wsu.structure.discipline.name.title')"
            :placeholder="$t('wsu.structure.discipline.name.placeholder')"
            :error="nameError"
            @input="nameError = false"
        />

        <ws-select
            class="mt-5"
            v-model="entityData.degrees_id"
            :items="degreesSelect"
            :label="$t('Degree')"
            :placeholder="$t('wsu.structure.degree.choose')"
            :error="degreeError"
            @input="degreeError = null"
            multiple
        />

        <v-row no-gutters>
          <v-col v-if="$store.state.structure.selectedEntityType === 'department'" cols="6">
            <ws-switch

                v-model="entityData.is_general"
                class="mt-3"
                :placeholder="$t('wsu.structure.discipline.general')"
                :tooltip="$t('wsu.structure.discipline.general_tooltip')"
            />
          </v-col>
          <v-col cols="6">
            <ws-switch
                v-model="entityData.on_choice"
                class="mt-3"
                :placeholder="$t('wsu.structure.discipline.on_choice')"

            />
          </v-col>
        </v-row>


      </ws-dialog>
      <!--  Import Disciplines -->
      <ws-file-import-dialog
          v-if="displayImportDialog"
          @success="initPage"
          v-model="displayImportDialog"
          entity="disciplines"
          :body="{[$store.state.structure.selectedEntityType + '_id'] : $store.state.structure.selectedEntityId}"
      />
      <!--  Delete Faculty-->
      <ws-dialog
          v-if="displayDeleteDialog"
          v-model="displayDeleteDialog"
          @delete="deleteEntity"
          :title="$t('wsu.structure.faculty.delete')"
          display-confirm-delete
          :confirm-delete-code="selectedItem ? selectedItem.uuid : null"
          close-delete
      />

    </template>

  </sub-page>
</template>

<script>
import wsFileImportDialog from "@/components/UI/wsFileImportDialog";
import {mapActions, mapState} from "vuex";

export default {
  name: "specialties",
  components : {
    wsFileImportDialog
  },
  data() {
    return {
      items : [],
      departmentsSelect : [],
      specialtiesSelect : [],
      specializationsSelect : [],
      degreesSelect : [],
      displayDialog : false,
      entityData : {},
      selectedItem : {},
      displayDeleteDialog : false,
      editEntity : false,
      nameError : false,
      degreeError : false,
      displayImportDialog : false
    }
  },
  computed : {
    ...mapState('structure' , [
        'selectedEntityType' ,
        'selectedEntityId',
        'selectedLang'
    ]),

    headerDropdownActionsSelect() {
      return [
        { text : this.$t('CreateNew') , action : this.openNewDiscipline  },
        { text : this.$t('ImportFromFile') , action : () => { this.displayImportDialog = true }  }
      ]
    },

    specializationsSelectFiltered() {
      let items = this.specializationsSelect
      if ( !items ) {
        return []
      }

      if ( !this.entityData.specialty_id) {
        return []
      }

      items = items.filter(el => el.specialty_id === this.entityData.specialty_id )


      return items
    },
    contextActionsSelect() {
      return [
        { text : this.$t('Edit') ,
          value : 'edit' , icon : 'mdi-pencil' ,
          action : () => { this.openEditFaculty() } },
        { text : this.$t('Delete') ,
          value : 'delete' ,
          icon : 'mdi-delete-outline' ,
          action : () => { this.displayDeleteDialog = true }
        }
      ]
    },
    specializationsSlectFiltered() {
      let items = this.specializationsSelect
      if ( !items ) {
        return []
      }

      return items
    },
    headers() {
      let items = [
        { text : this.$t('Name') , value : 'name' },
        { text : this.$t('wsu.structure.discipline.on_choice') , value : 'on_choice' , width : 10 , align: 'center' },
        { text : this.$t('wsu.structure.discipline.general') , value : 'is_general' , width : 10 , align: 'center' },
        { text : this.$t('Degree') , value : 'degrees_names' , width : 120 }
      ]
      if (this.$store.state.structure.selectedEntityType === 'specialty') {
        items.splice(2,1)
      }
      return items
    }
  },
  methods : {
    ...mapActions('structure' , [
        'GET_DISCIPLINES' ,
        'ADD_EDIT_DISCIPLINE' ,
        'DELETE_DISCIPLINE'
    ]),

    selectEntity(item) {
      this.$store.state.structure.returnEntityType.push(this.$store.state.structure.selectedEntityType)
      this.$store.state.structure.returnEntityId.push(this.$store.state.structure.selectedEntityId)
      this.$store.state.structure.returnEntityName.push(this.$store.state.structure.selectedEntityName)

      this.$store.state.structure.selectedEntityType = 'specialty'
      this.$store.state.structure.selectedEntityId = item.uuid
      this.$store.state.structure.selectedEntityName = item.name
      this.$router.push(this.businessDashLink(`structure/description?entity=institute&uuid=${item.uuid}`))
    },
    // AJAX
    async addEntity() {

      if ( !this.entityData.name ) {
        this.nameError = true
        return this.notify(this.$t('wsu.structure.discipline.name.error') , 'warning')
      }


      this.entityData.lang = this.selectedLang
      if ( this.selectedEntityType === 'specialty') {
        this.entityData.university_specialty_id = this.selectedEntityId
      } else {
        this.entityData.department_id = this.selectedEntityId
      }

      let result = await this.ADD_EDIT_DISCIPLINE(this.entityData)
      if ( !result ) {
        this.notify(this.$t('NetworkError'))
        return
      }


      if ( !this.editEntity ) {
        this.items.push(result)
        this.notify(this.$t('wsu.structure.discipline.created') , 'success')
      } else {
        let index = this.items.findIndex(el => el.uuid === result.uuid )
        if (index === -1 ) {
          return
        }
        this.items[index] = result
        this.items = this.COPY(this.items)
        this.notify(this.$t('ChangedSaved'))
      }

      this.displayDialog = false
    },
    async deleteEntity() {
      let result = await this.DELETE_DISCIPLINE(this.selectedItem.uuid)
      if ( !result ) {
        return this.notify(this.$t('NetworkError'))
      }
      let index = this.items.findIndex(el => el.uuid === this.selectedItem.uuid )
      if (index === -1 ) {
        return
      }
      this.items.splice(index , 1)
      this.notify(this.$t('wsu.structure.discipline.deleted') )
      this.displayDeleteDialog = false
      this.displayDialog = false
    },
    // Methods
    openEditFaculty() {
      this.entityData = this.COPY(this.selectedItem)
      this.editEntity = true
      this.displayDialog = true
    },
    openNewDiscipline() {
      this.editEntity = false
      this.entityData = {}
      this.displayDialog = true
    },
    // Technical
    async initPage() {
      let data = {
        parent :  this.$store.state.structure.selectedEntityType,
        parent_id : this.$store.state.structure.selectedEntityId,
        lang : this.selectedLang
      }
      let result = await this.GET_DISCIPLINES(data)
      this.items = result.disciplines || []
      this.degreesSelect = result.degrees_select


    },
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>